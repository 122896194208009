var render = function () {
  var _vm$item$accountId, _vm$item$bookingId, _vm$item$paymentStatu, _vm$item$paymentStatu2, _vm$item$loggedPaymen, _vm$item$loggedPaymen2, _vm$item$loggedPaymen3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item,
      "tab": 0,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Reason")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.reason) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('account', _vm.item.accountId._id)
    }
  }, [_c('v-list-item-title', [_vm._v("Host profile")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$accountId = _vm.item.accountId) === null || _vm$item$accountId === void 0 ? void 0 : _vm$item$accountId.name) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('booking', _vm.item.bookingId._id)
    }
  }, [_c('v-list-item-title', [_vm._v("Booking")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$bookingId = _vm.item.bookingId) === null || _vm$item$bookingId === void 0 ? void 0 : _vm$item$bookingId.bookingNumber) + " ")])], 1), (_vm$item$paymentStatu = _vm.item.paymentStatusId) !== null && _vm$item$paymentStatu !== void 0 && _vm$item$paymentStatu.name ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Status")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("uppercase")((_vm$item$paymentStatu2 = _vm.item.paymentStatusId) === null || _vm$item$paymentStatu2 === void 0 ? void 0 : _vm$item$paymentStatu2.name)) + " ")])], 1) : _vm._e(), _vm.item.paymentType ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Type")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("uppercase")(_vm.item.paymentType)) + " ")])], 1) : _vm._e(), _vm.item.cost ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Cost")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.item.cost)) + " ")])], 1) : _vm._e(), _vm.item.paymentNumber ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Payment Number")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.paymentNumber) + " ")])], 1) : _vm._e(), _vm.item.paymentService ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Payment Service")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("uppercase")(_vm.item.paymentService)) + " ")])], 1) : _vm._e()], 1), _vm.item.loggedPaymentInfo ? _c('vx-card-list', {
    attrs: {
      "title": "Payment info",
      "item": _vm.item,
      "tab": 0
    }
  }, [(_vm$item$loggedPaymen = _vm.item.loggedPaymentInfo) !== null && _vm$item$loggedPaymen !== void 0 && _vm$item$loggedPaymen.reference ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Reference")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.loggedPaymentInfo.reference) + " ")])], 1) : _vm._e(), (_vm$item$loggedPaymen2 = _vm.item.loggedPaymentInfo) !== null && _vm$item$loggedPaymen2 !== void 0 && _vm$item$loggedPaymen2.paidAt ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Paid At")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.item.loggedPaymentInfo.paidAt)) + " ")])], 1) : _vm._e(), (_vm$item$loggedPaymen3 = _vm.item.loggedPaymentInfo) !== null && _vm$item$loggedPaymen3 !== void 0 && _vm$item$loggedPaymen3.sourceId ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Source")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.loggedPaymentInfo.sourceId.name) + " ")])], 1) : _vm._e()], 1) : _vm._e(), _c('vx-card-list-meta', {
    attrs: {
      "item": _vm.item
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }