<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :item="item" :tab="0" hide-edit>
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Reason</v-list-item-title>
            <v-list-item-subtitle> {{ item.reason }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('account', item.accountId._id)">
            <v-list-item-title>Host profile</v-list-item-title>
            <v-list-item-subtitle> {{ item.accountId?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('booking', item.bookingId._id)">
            <v-list-item-title>Booking</v-list-item-title>
            <v-list-item-subtitle> {{ item.bookingId?.bookingNumber }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.paymentStatusId?.name">
            <v-list-item-title>Status</v-list-item-title>
            <v-list-item-subtitle> {{ item.paymentStatusId?.name | uppercase }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.paymentType">
            <v-list-item-title>Type</v-list-item-title>
            <v-list-item-subtitle> {{ item.paymentType | uppercase }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.cost">
            <v-list-item-title> Cost</v-list-item-title>
            <v-list-item-subtitle> {{ item.cost | price }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.paymentNumber">
            <v-list-item-title>Payment Number</v-list-item-title>
            <v-list-item-subtitle> {{ item.paymentNumber }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.paymentService">
            <v-list-item-title>Payment Service</v-list-item-title>
            <v-list-item-subtitle> {{ item.paymentService | uppercase }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.loggedPaymentInfo" title="Payment info" :item="item" :tab="0">
          <v-list-item v-if="item.loggedPaymentInfo?.reference">
            <v-list-item-title>Reference</v-list-item-title>
            <v-list-item-subtitle> {{ item.loggedPaymentInfo.reference }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.loggedPaymentInfo?.paidAt">
            <v-list-item-title>Paid At</v-list-item-title>
            <v-list-item-subtitle> {{ item.loggedPaymentInfo.paidAt | dateTime }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.loggedPaymentInfo?.sourceId">
            <v-list-item-title>Source</v-list-item-title>
            <v-list-item-subtitle> {{ item.loggedPaymentInfo.sourceId.name }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list-meta :item="item" />
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PaymentService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
    };
  },
  async created() {
    await this.getPayments();
  },
  methods: {
    async getPayments() {
      const response = await PaymentService.get(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.paymentNumber);
      }
    },
  },
};
</script>
